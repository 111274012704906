/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-discord': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.71C13.256.243 11.11.02 9 0 6.89.019 4.744.243 3.568.692 1.386 1.539.667 3.752.518 4.303c0 .003.002-.003-.007.03a8.063 8.063 0 00-.053.224 18.961 18.961 0 00-.456 4.434c-.018 1.366.085 2.888.442 4.4a20.084 20.084 0 00.075.305l.014.052c.177.627.916 2.737 3.035 3.56 1.176.449 3.323.673 5.432.692 2.11-.019 4.256-.224 5.432-.692 2.181-.847 2.9-3.059 3.05-3.611 0-.003-.002.003.006-.03l.022-.085.032-.14c.35-1.528.474-3.069.456-4.433.018-1.378-.087-2.914-.434-4.42l-.035-.152a7.122 7.122 0 00-.023-.091l-.009-.035c-.155-.571-.88-2.759-3.046-3.6z" _fill="#898B9B"/><path pid="1" d="M14.09 4.651l-.04-.1a.012.012 0 00-.006-.007l-.098-.045c-.811-.368-1.955-.459-2.967-.499H9.84c-.013 0-.017.017-.005.023l2.341 1.122c.014.007.006.026-.008.023A12.537 12.537 0 009 4.753c-1.047 0-2.107.139-3.157.412-.014.004-.022-.016-.008-.022l2.423-1.12C8.27 4.018 8.266 4 8.253 4H7.021c-1.012.04-2.156.131-2.967.5l-.098.044a.012.012 0 00-.007.007l-.04.1c-.83 2.103-1.065 5.673-.813 7.91l.013.117.093.072c1.296 1.005 3.03 1.522 4.12 1.66l.141.017a.1.1 0 00.106-.064l.71-1.896a.1.1 0 00-.078-.134l-.218-.034c-.76-.12-1.835-.435-2.744-1.26-.02-.018-.001-.05.023-.04.074.027.156.06.24.092.205.08.418.163.557.196.975.232 1.964.35 2.94.35v-.018h.003v.018c.975 0 1.964-.118 2.94-.35.138-.033.35-.116.556-.196.084-.033.166-.065.24-.092.024-.01.043.022.023.04-.906.82-1.983 1.136-2.744 1.256l-.218.034a.1.1 0 00-.078.133l.71 1.901a.1.1 0 00.106.064l.14-.018c1.091-.137 2.825-.654 4.12-1.659l.094-.072.013-.118c.252-2.236.016-5.806-.814-7.909zM6.683 9.814a1.417 1.417 0 01-1.538-1.538c.06-.676.604-1.22 1.28-1.28a1.417 1.417 0 011.539 1.538 1.418 1.418 0 01-1.28 1.28zm4.892 0a1.417 1.417 0 01-1.538-1.538c.06-.676.604-1.22 1.28-1.28a1.417 1.417 0 011.538 1.538 1.418 1.418 0 01-1.28 1.28z" _fill="#fff"/>'
  }
})
